<template>
   <div class="landing-wrap">
      <div class="container-in">
         <img :src="publicOrganizationData.logoColor[0].url" alt="">
         <h4 v-if="step != 2">Change Password</h4>
         <h6  v-if="step != 2" style="max-width: 500px; margin:10px auto;" >Enter the username or email associated with your account to receive a temporary code.</h6>
         <div v-if="step != 2" class="smart-wrap" style="width: 400px;margin: 0 auto;">
            <div class="input-wrap col-12">
               <label for="">User Name</label>
               <input type="text" v-model="user">
            </div>
            <h5>- or -</h5>
            <div class="input-wrap col-12">
               <label for="">Email</label>
               <input type="text" v-model="email">
            </div>
            <UiButton :style="'margin: 20px auto; background:'+colors[0]+';'" text="Send Code" v-on:click="setStatus()"/>
         </div>
         <div v-if="step == 1" class="smart-wrap" style="width: 400px;margin: 0 auto;">
            <h6>{{ response }}</h6>
            <div class="input-wrap col-12">
               <label for="">Code</label>
               <input type="text" v-model="code">
            </div>
            <div class="input-wrap col-12">
               <label for="">New Password</label>
               <input type="text" v-model="pass[0]">
            </div>
            <div class="input-wrap col-12">
               <label for="">Repeat New Password</label>
               <input type="text" v-model="pass[1]" v-on:keyup="() => { pass[0].startsWith(pass[1])  ? samePass =true : samePass = false; }">
            </div>
            <div v-if="!samePass" class=" col-12">
               <h6>Passwords are not the same</h6>
            </div>
            <UiButton v-if="samePass && pass[1] && code" :style="'margin: 20px auto; background:'+colors[0]+';'" text="Change Password" v-on:click="updatePass()" />
         </div>
         <div v-if="step == 2" class="smart-wrap" style="width: 400px;margin: 0 auto;">
            <h4>{{ response }}</h4>
            <router-link to="/smartdash" class="btn-g show gray" :style="'margin: 20px auto; color: #fff; background:'+colors[0]+';'" >Login</router-link>
         </div>
      </div>
   </div>
</template>
<script>
//Tools
import Vuex from "vuex";
import { Auth, API, Storage } from "aws-amplify";
import { popUp, loading, orderColors} from "../../store/tools";

//Components
import UiButton from "../../components/ui/UiButton.vue";
import PaperLayout from "../../components/papers/PaperLayout.vue";

//Back End
import { smd_updatePassword, smd_sendResetPassword } from "../../graphql/mutations";

export default {
   components: {
      UiButton,
   },
   name: " Forgot",
   data() {
      return {
         user:'',
         email: '',
         pass:[],
         samePass:true,
         code:'',
         step:0,
         response: '',
         colors:[]
      };
   },
   created() {
      this.pass=[]
      this.colors = orderColors(this.publicOrganizationData.colorSeriesA)
   },
   methods: {
      async setStatus() {
         console.clear()
         loading('show','')
         let pullData = ''
         let pushData = {
            PK: this.publicOrganization,
            email: this.email,
            userName: this.user,
         }
         console.log(pushData);
         try {
            pullData = await API.graphql({
               query: smd_sendResetPassword,
               variables: pushData,
            });
            pullData = pullData.data.smd_sendResetPassword
            console.log(pullData);
            if (pullData.smd_response.statusCode != '200') {  
               popUp(pullData.smd_response, 'response', 'err') 
               loading('hidde', 'getItem')
               return
            }
            else{
              //popUp(pullData.smd_response, 'response', 'scc')
               this.step = 1
               this.response =pullData.smd_response.userMessage
               loading('hidde', 'getItem')
            }
         } catch (error) {
            popUp(error, 'cach', 'err')
            console.log(error);
         }
      },
      async updatePass() {
         console.clear()
         loading('show','')
         let pullData = ''
         let pushData = {
            PK:this.publicOrganization,
            email: this.email,
            pass: this.pass[1],
            code:this.code,
            userName: this.user,
         }
         console.log(pushData);
         try {
            pullData = await API.graphql({
               query: smd_updatePassword,
               variables: pushData,
            });
            pullData = pullData.data.smd_updatePassword
            console.log(pullData);
            if (pullData.smd_response.statusCode != '200') {  
               popUp(pullData.smd_response, 'response', 'err')
               loading('hidde', 'getItem')
               return
            }
            else{
              //popUp(pullData.smd_response, 'response', 'scc')
               this.step = 2
               this.response =pullData.smd_response.userMessage
               loading('hidde', 'getItem')
            } 
         } catch (error) {
            popUp(error, 'cach', 'err')
            console.log(error);
         }
         loading('hidde', 'getItem')
      },
   },
   computed:{
      ...Vuex.mapState(["organizationID",'organizationData', 'publicOrganization','user','publicOrganization', 'publicOrganizationData','currentItem'])
   }
}
</script>
<style lang="less">
.smart-wrap {
   display: flex;
   align-items: center;
   justify-content: center;
}
.landing-wrap{
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   .container-in{
      background: #fff;
      padding: 50px 40px;
      border-radius: 40px 50px;
      text-align: center;
      img{
         width: 200px;
         margin: 0 auto 20px;
         display: block;
      }
   }
}
</style>
